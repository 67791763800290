<template>
  <PageContainer
    :title="pageContainerConfig.title"
    :back="pageContainerConfig.back"
    :submit="pageContainerConfig.submit"
  >
    <template v-slot:content>
      <template v-if="loading">
        <Skeleton width="100%" height="240px" />

        <div class="segment-content">
          <Skeleton
            width="100%"
            height="126px"
            margin="32px 0"
            borderRadius="8px"
          />
          <Skeleton
            width="100%"
            height="50px"
            margin="8px 0"
            borderRadius="8px"
          />

          <div class="margin-y" v-for="(v, i) in 2" :key="i">
            <Skeleton
              width="190px"
              height="30px"
              borderRadius="8px"
              margin="16px 0"
            />

            <Skeleton
              width="100%"
              height="50px"
              borderRadius="8px"
              margin="0"
            />
          </div>

          <Skeleton
            width="100%"
            height="150px"
            margin="8px 0"
            borderRadius="8px"
          />
        </div>
      </template>

      <template v-if="conference && !loading">
        <div class="page-segment padding-all-zero">
          <div class="conference-detail-cover">
            <img
              class="conference-detail-cover-img"
              :src="conference.cover"
              :alt="conference?.name"
            />
            <div class="conference-detail-cover-content">
              <Chip
                :text="getChipConfig.text"
                :color="getChipConfig.color"
                size="small"
                v-if="getChipConfig.visible"
              />
              <div class="heading-display text-white" v-text="conference?.name" />
            </div>
          </div>

          <div class="page-segment-content conference-detail-container">
            <div class="conference-detail-items">
              <StatItem
                :text="conferenceDate.day"
                :title="conferenceDate.fullDate"
                :sub-title="conferenceDate.time"
              />

              <StatItem
                icon="icon-timer-off"
                :title="$translations.form.duration"
                :sub-title="`${$moment.duration(conference.duration, 'seconds').humanize()}`"
              />

              <StatItem
                icon="icon-video-outline-off"
                :title="$translations.form['service-type']"
                :sub-title="$translations.appointments.virtual.true"
              />

              <StatItem
                icon="icon-money-off"
                :title="$translations.form.price"
                :sub-title="conferencePrice"
              />
            </div>

            <Button
              :text="getRoomLinkConfig.text"
              color="purple"
              @clicked="openBrowser"
              :disable="getRoomLinkConfig.disable"
              v-if="getRoomLinkConfig.visible"
            />

            <!-- capacity -->
            <div class="conference-detail-capacity-title heading-small" v-text="$translations.conferences.capacity"/>
            <div class="conference-detail-capacity">
              <div class="icon-specialists-off text-gray-50"/>
              <div class="body" v-text="capacityText" />
            </div>

            <!-- description -->
            <div class="conference-detail-description">
              <div class="heading-small" v-text="$translations.conferences['about-conference']"/>
            </div>

            <div class="body" v-html="conference.description" />
          </div>
        </div>

        <div class="page-segment" v-if="specialistInfo && specialistInfo.name">
          <div class="page-segment-content">
            <!-- Host -->
            <div class="heading-small">
              {{ $translations.conferences.host }}
            </div>

            <!-- specialist card -->
            <PersonCard
              v-selectable-container="{ callback: navigateToSpecialistProfile }"
              class="margin-top"
              :title="specialistInfo?.name"
              :image="specialistInfo.pic"
              :middle-line="specialistInfo.speciality"
              :bottom-line="specialistInfo.address"
              :show-action="Boolean(specialistInfo.id)"
            />
          </div>
        </div>
      </template>
    </template>
  </PageContainer>
</template>

<script>
import {
  Conferences,
  UsrConferences,
  PageContainer,
  Chip,
  ConferenceStatustype,
  CurrencyFilter,
  StatItem,
  Button,
  PersonCard,
  Skeleton,
  Subscriber,
  selectableContainer
} from '@seliaco/red-panda'
import { Browser } from '@capacitor/browser'
import { BookingAndAppointmentDetailEvent } from '@/types/events'

export default {
  components: {
    PageContainer,
    Chip,
    StatItem,
    Button,
    PersonCard,
    Skeleton
  },
  directives: {
    selectableContainer
  },
  data () {
    return {
      loading: false,
      conference: null,
      firstDate: null,
      secondDate: null,
      pageContainerConfig: {
        title: this.$translations.conferences.title,
        back: {
          callback: () => {
            if (this.$route.query.back) {
              this.$router.replace({
                path: this.$route.query.back
              })
            } else {
              this.$router.push({ name: 'Home' })
            }
          }
        },
        submit: null
      },
      toggleDescription: false,
      interval: null,
      time: new Date(),
      userExists: false,
      quotaInProgress: null
    }
  },
  beforeUnmount () {
    this.subscriber.removeSubscription()
    clearInterval(this.interval)
  },
  mounted () {
    this.getConference()
    this.subscribeToChannel()
  },
  methods: {
    subscribeToChannel () {
      this.subscriber = Subscriber.createChannel(
        `conferences:id=eq.${this.$route.params.id}`
      )
      this.subscriber.updateEvent(this.receiveMessage)
    },
    receiveMessage (params) {
      this.conference.ability = params.new.ability
      this.conference.status = params.new.status

      if (this.conference.ability === 0) {
        this.pageContainerConfig.submit = {
          text: this.$translations.conferences.buttons['submit-disable'],
          disabled: true,
          callback: () => {}
        }
      } else {
        this.pageContainerConfig.submit = {
          text: this.$translations.conferences.buttons.submit,
          callback: this.handlerCheckout
        }
      }
    },
    async getConference () {
      this.loading = true

      this.conference = await UsrConferences.get(this.$route.params.id).then((res) => res.data)

      // Test
      // this.conference.start_date = '2022-04-06T23:47:00+00:00'

      const diffSeconds = this.$moment(
        this.conference.start_date.split('+')[0]
      ).diff(this.$moment(), 's')

      // timer init
      if (diffSeconds < 86400 && diffSeconds > 0) {
        this.time = this.$moment('2022-04-04').add(diffSeconds, 'seconds')
        this.interval = setInterval(() => {
          this.time = this.$moment(this.time).subtract(1, 'seconds').toDate()
          // finish timer
          if (this.$moment(this.time).format('HH:mm:ss') === '00:00:00') {
            this.interval = null
            this.pageContainerConfig.submit = null
            this.conference.status = null
          }
        }, 1000)
      }

      const date = this.conference?.start_date.split('+')[0]

      this.firstDate = this.$moment(date)

      this.secondDate = this.$moment(date).add(
        this.conference?.duration,
        'minute'
      )

      // conference is closed
      if (
        this.$moment(new Date()).isSameOrAfter(this.firstDate) &&
        this.$moment(new Date()).isBefore(this.secondDate)
      ) {
        this.conference.status = ''
      }

      if (this.$moment(new Date()).isAfter(this.secondDate)) {
        this.conference.status = 'FINISHED'
      }

      this.userExists = this.conference.conference_quotas.find((i) => {
        if (i.payment_status === 'CHECKOUT_IN_PROGRESS' && i.user.id === this.$store.getters['auth/user'].id) {
          this.quotaInProgress = i.id
        }
        if (i.payment_status !== 'PAID_TICKET') {
          return null
        }
        if (i.user.id === this.$store.getters['auth/user'].id) {
          return i
        }
      })

      // validations for submit button
      if (!this.userExists) {
        if (this.conference.status === ConferenceStatustype.AVAILABLE) {
          this.pageContainerConfig.submit = {
            text: this.$translations.conferences.buttons.submit,
            callback: this.handlerCheckout
          }
        }
        if (this.conference.status === ConferenceStatustype.SOLD_OUT) {
          this.pageContainerConfig.submit = {
            text: this.$translations.conferences.buttons['submit-disable'],
            disabled: true,
            callback: () => {}
          }
        }
      }

      this.loading = false
    },
    handlerCheckout () {
      this.pageContainerConfig.submit.loading = true

      if (this.quotaInProgress) {
        this.$router.push({
          name: 'CheckOut',
          params: {
            id: this.conference.id,
            type: 'CONFERENCE'
          },
          query: {
            quotaId: this.quotaInProgress
          }
        })
      } else {
        Conferences.takePlaceAtConference(this.$route.params.id)
          .then((response) => {
            if (response) {
              const { conference, quota } = response
              this.$router.push({
                name: 'CheckOut',
                params: {
                  id: conference.id,
                  type: 'CONFERENCE'
                },
                query: {
                  quotaId: quota.id
                }
              })
            }
          })
          .finally(() => (this.pageContainerConfig.submit.loading = false))
      }
    },
    navigateToSpecialistProfile () {
      if (!this.conference?.conference_speakers[0]?.specialist?.id) {
        return
      }

      const body = {
        user: this.$store.getters['auth/user'],
        origin: this.$route.name,
        specialist_id: this.conference?.conference_speakers[0]?.specialist.id,
        specialist_name: this.specialistInfo?.name,
        specialist: this.conference?.conference_speakers[0]?.specialist,
        component: 'Person card',
        is_public: this.conference?.conference_speakers[0]?.specialist.is_public
      }
      BookingAndAppointmentDetailEvent.Select_View_Specialist(body)

      this.$router.push({
        name: 'SpecialistDetail',
        params: {
          id: this.conference?.conference_speakers[0]?.specialist.id
        },
        query: {
          back: this.$route.fullPath
        }
      })
    },
    openBrowser () {
      Browser.open({
        url: this.conference.room_link
      })
    }
  },
  computed: {
    conferenceDate () {
      const date = this.$moment(this.conference.start_date.split('+')[0])

      return {
        day: date.format('ddd'),
        fullDate: date.format('D MMM, YYYY'),
        time: date.format('hh:mm a')
      }
    },
    conferencePrice () {
      const price = this.conference.price || 0

      return CurrencyFilter(price / 100)
    },
    capacityText () {
      const text = this.$translations.conferences.ability

      return text
        .replace('{{ability}}', this.conference.ability)
        .replace('{{capacity}}', this.conference.capacity)
    },

    specialistInfo () {
      const speaker = this.conference?.conference_speakers[0]

      if (speaker.name) {
        return {
          name: speaker.name,
          pic: speaker.picture
        }
      }

      const specialist = speaker.specialist

      return {
        name: `${specialist?.user?.first_name} ${specialist?.user?.last_name}`,
        address:
          specialist?.user?.address?.city && specialist?.user?.address?.country
            ? `${specialist.user.address.city}, ${specialist.user.address.country}`
            : '',
        speciality: specialist?.type.name,
        pic: specialist?.user?.profile_picture
      }
    },
    getChipConfig () {
      let color = ''
      let text = ''
      let visible = true

      switch (this.conference.status) {
        case ConferenceStatustype.AVAILABLE:
          color = 'green-dark'
          text = this.$translations.conferences.state.available
          break
        case ConferenceStatustype.SOLD_OUT:
          color = 'red-dark'
          text = this.$translations.conferences.state['sold-out']
          break
        case ConferenceStatustype.FINISHED:
          color = 'green-dark'
          text = this.$translations.conferences.state.finished
          break
        case ConferenceStatustype.CANCELED:
          color = 'red-dark'
          text = this.$translations.conferences.state.canceled
          break
      }

      if (
        this.userExists?.payment_status === 'PAID_TICKET' &&
        this.$moment(new Date()).isBefore(this.firstDate)
      ) {
        visible = false
      }

      return {
        color,
        text,
        visible
      }
    },
    getRoomLinkConfig () {
      let visible = Boolean(this.conference.room_link && this.userExists)
      let disable = visible
      let text = ''

      if (
        this.userExists &&
        this.userExists?.payment_status === 'PAID_TICKET'
      ) {
        text = this.$translations.conferences['enter-conference']
        const diffDays = this.$moment(
          this.conference.start_date.split('+')[0]
        ).diff(this.$moment(), 'd')

        if (this.interval) {
          text = this.$moment(this.time).format('HH:mm:ss')
        } else if (diffDays >= 1) {
          const translation = this.$translations.buttons['diff-time']
          const textDays = diffDays > 1 ? translation.days : translation.day

          text = `${diffDays} ${textDays}`
        } else {
          disable = false
        }
      }

      if (text === '' || this.$moment(new Date()).isSameOrAfter(this.secondDate)) {
        visible = false
      }

      return {
        visible,
        disable,
        text
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.conference-detail
  &-cover
    position: relative
    &::before
      content: ''
      width: 100%
      height: 100%
      background: rgba(62, 64, 71, 0.5)
      position: absolute
      top: 0
      left: 0
      z-index: 1
      backdrop-filter: blur(4px)
    &-img
      height: 240px
      width: 100%
      object-fit: cover
    &-content
      position: absolute
      bottom: 20px
      left: 20px
      display: flex
      flex-direction: column
      gap: 16px
      z-index: 2

  &-container
    padding: 24px 16px
  &-items
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 30px 16px
    margin-bottom: 32px

  &-capacity-title
    margin-top: 28px
  &-capacity
    margin-top: 16px
    display: flex
    gap: 8px
    align-items: center
    .icon-specialists-off
      font-size: var(--lg)

  &-description
    margin-top: 36px
    display: flex
    justify-content: space-between
    margin-bottom: 8px
    align-items: center
    .icon-arrow-small-outline-down,
    .icon-arrow-small-outline-up
      font-size: var(--xl-3)

@media (min-width: 1040px)
  .conference-detail-container
    padding: 24px
  .conference-detail-cover
    &-content
      left: calc((1040px - 480px)/2 + 20px)
      width: 480px
    img,
    &::before
      border-radius: 16px 16px 0 0
</style>
